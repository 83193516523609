<template>
  <div>
    <Card>
      <template #body>
        <div class="text-end card-padding">
          <v-row class="mb-3">
            <v-col cols="4">
              <!-- <label class="text-start text-typo text-sm">
                {{ $t("project.project_name") }} :
              </label>
              <label class="text-start text-typo text-sm">
                {{ data.pro }}
              </label> -->
              <!-- <Input :item="projects"> </Input> -->
            </v-col>
            <v-col cols="4">
              <!-- <Input :item="start_date"> </Input> -->
            </v-col>
            <v-col cols="4">
              <!-- <Input :item="end_date"> </Input> -->
            </v-col>
          </v-row>
          <v-data-table
            :headers="header"
            :items="items"
            :items-per-page="items.length"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:[`item.count_hour`]="{ item }">
              {{ get_hour(item.end_actual_time, item.start_actual_time, item) }}
              <input
                class="pt-0 mt-0"
                type="hidden"
                v-model="item.count_hour"
              />
            </template>
            <template v-slot:[`item.note`]="{ item }">
              <v-text-field
                class="pt-0 mt-0"
                type="text"
                hide-details
                v-model="item.note"
              ></v-text-field>
            </template>
          </v-data-table>
          <div class="mt-3 pt-4" v-if="data">
            <table class="w-100">
              <tr>
                <td class="w-30">
                  <h5 class="text-typo text-sm text-center">
                    {{ $t("daily.total_count_hour") }}
                  </h5>
                </td>
                <td class="w-20">
                  <h5 class="text-start text-typo text-sm">
                    {{ data.count_hour }}
                  </h5>
                </td>
                <td class="w-10"></td>
                <td class="w-20">
                  <h5 class="text-typo text-sm text-center">
                    {{ $t("daily.total_amount") }}
                  </h5>
                </td>
                <td class="w-40">
                  <h5 class="text-center text-typo text-sm">
                    {{ data.price }}
                  </h5>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h5 class="mx-16 text-typo text-sm text-end">
                    {{ $t("request.tax") }}
                  </h5>
                </td>
                <td>
                  <h5 class="text-center text-typo text-sm">
                    {{ tax }}
                  </h5>
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  <h5 class="mx-16 text-typo text-sm text-end">
                    {{ $t("request.total price") }}
                  </h5>
                </td>
                <td>
                  <h5 class="text-center text-typo text-sm">
                    {{ data.total_price }}
                  </h5>
                </td>
              </tr>
            </table>
            <div class="text-end mt-2">
              <Button
                v-if="items.length"
                classes="bg-gradient-primary mb-3"
                @click="save"
                :loader="loader"
              ></Button>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
  <script>
import Card from "../Components/Card";
import Input from "../Components/Input";
import Button from "../Components/Button";
import requestService from "../../../services/request.service";

export default {
  components: {
    Card,
    Button,
    Input,
  },
  data() {
    return {
      data: null,
      projects: {
        type: "autocomplete",
        label: this.$i18n.t("project.project_name"),
        value_text: "project_id",
        items: [],
        value: "",
        title_select: "project_name",
        return_object: true,
      },
      start_date: {
        type: "date",
        label: this.$i18n.t("daily.start_date"),
        value_text: "start_date",
        items: [],
        value: "",
      },
      end_date: {
        type: "date",
        label: this.$i18n.t("daily.end_date"),
        value_text: "end_date",
        items: [],
        value: "",
      },
      loader: false,
      card: {
        title: this.$i18n.t("UpdateRequest"),
      },
      tax: 0,
      items: [],
      header: [
        {
          text: this.$i18n.t("request.description"),
          align: "center",
          value: "note",
        },
        {
          text: this.$i18n.t("daily.number_week"),
          align: "center",
          value: "number_week",
        },
        {
          text: this.$i18n.t("daily.location"),
          align: "center",
          value: "location",
        },
        {
          text: this.$i18n.t("request.employee_total"),
          align: "center",
          value: "employee_total",
        },
        {
          text: this.$i18n.t("daily.start_actual_time"),
          align: "center",
          value: "start_actual_time",
        },
        {
          text: this.$i18n.t("daily.end_actual_time"),
          align: "center",
          value: "end_actual_time",
        },
        {
          text: this.$i18n.t("daily.count_hour"),
          align: "center",
          value: "count_hour",
        },
      ],
    };
  },
  //   computed: {
  //     count_hour() {
  //       return this.header.filter((v) => v.value == "count_hour")[0].value;
  //     },
  //   },
  watch: {
    // count_hour: {
    //   handler: function (value) {
    //     console.log("value", value);
    //   },
    // },
    // selected(val) {
    //   console.log(val);
    //   var self = this;
    //   self.total_count_hour = 0;
    //   self.total_amount = 0;
    //   val.forEach((v) => {
    //     self.total_count_hour += parseInt(v.count_hour);
    //     self.total_amount += parseFloat(v.hourly_fare * this.price_of_project);
    //   });
    // },
  },
  methods: {
    get_hour(end_date, start_date, item) {
      // alert(end_date + ' ' + start_date)
      // تحويل الوقتين إلى كائنات تاريخ
      if (end_date < start_date) {
        var date1 = new Date();
        date1.setDate(date1.getDate() + 1);
        date1.setHours(Number(end_date.split(":")[0]));
        date1.setMinutes(Number(end_date.split(":")[1]));
        if (end_date.split(":")[2])
          date1.setSeconds(Number(end_date.split(":")[2]));
      } else {
        var date1 = new Date();
        date1.setHours(Number(end_date.split(":")[0]));
        date1.setMinutes(Number(end_date.split(":")[1]));
        if (end_date.split(":")[2])
          date1.setSeconds(Number(end_date.split(":")[2]));
      }

      var date2 = new Date();
      date2.setHours(Number(start_date.split(":")[0]));
      date2.setMinutes(Number(start_date.split(":")[1]));
      if (start_date.split(":")[2])
        date2.setSeconds(Number(start_date.split(":")[2]));

      // حساب الفرق بين الوقتين بالميلي ثانية
      console.log("date1 ", date1);
      console.log("date2 ", date2);
      var timeDiffMilliseconds = date1 - date2;
      var hoursDiff = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60));
      //   return (count = hoursDiff);
      var result = hoursDiff * item.employee_total;
      item.count_hour = result;
      return result;
    },
    save() {
      console.log(this.items);
      //   return;
      this.loader = true;
      return requestService
        .update(this.$route.params.id, {
          items: this.items,
        })
        .then(
          (response) => {
            this.loader = false;
            this.$router.push({
              name: "Requests",
              params: {
                message: {
                  msg: response.data.message,
                  type: "Success",
                },
              },
            });
            // this.$store.commit("form/SET_NOTIFY", {
            //   msg: response.data.message,
            //   type: "Success",
            // });
          },
          (error) => {
            this.loader = false;
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
          }
        );
    },
  },
  mounted() {
    this.card.title = document.title =
      this.$i18n.t("UpdateRequest") + " # " + this.$route.params.id;
    this.$store.commit("SET_CARD", this.card);
    this.$store.commit("SET_CARD_LOADING", true);
    return requestService.edit(this.$route.params.id).then(
      (response) => {
        this.$store.commit("SET_CARD_LOADING", false);
        // this.projects.items = response.data.data;
        // this.start_date.value =data.date;
        // this.end_date.value =data.date;
        var data = response.data.data;
        this.data = data;
        // this.total_count_hour = data.count_hour;
        // this.total_amount = data.price;
        // this.total_price = data.total_price;
        this.tax = response.data.tax;
        this.items = response.data.details;
      },
      (error) => {
        this.$store.commit("SET_CARD_LOADING", false);
        this.$store.commit("form/SET_NOTIFY", {
          msg: error.response.data.message,
          type: "Danger",
        });
      }
    );
  },
};
</script>