
class RequestService {
  get_requests(page, data) {
    return axios.post('requests?page=' + page, data)
  }
  edit(id) {
    return axios.get('requests/edit/' + id)
  }
  store(data) {
    return axios.post('requests/store', data)
  }
  store_request_details(data) {
    return axios.post('requests/store-request-details', data)
  }
  update(id, data) {
    return axios.post('requests/update/' + id, data)
  }
  destroy(id) {
    return axios.delete('requests/delete/' + id)
  }
  details(id) {
    return axios.get('requests/get-details-request/' + id)
  }
  create() {
    return axios.get('requests/create')
  }
  get_data_by_project(id, data) {
    return axios.post('requests/get_data_by_project/' + id, data)
  }

}
export default new RequestService();