var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-6 py-4"},[_c('Notify'),_c('v-row',[(_vm.filters && _vm.filters.length > 0)?_c('v-expansion-panels',{staticClass:"pa-2"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t("Filters"))+" ")]),_c('v-expansion-panel-content',[_c('FormFilter')],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('h5',{staticClass:"text-h5 mb-0 mt-3 text-typo font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.card && _vm.card.title)+" ")])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"6"}},[(
          _vm.card && _vm.card.permission && _vm.str_per.indexOf(_vm.card.permission) > -1
        )?_c('v-btn',{staticClass:"mx-2 mt-2 btn-primary bg-gradient-primary",attrs:{"dark":"","link":"","to":_vm.card.add_url}},[_c('h5',[_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("card.Add new"))+" ")],1)]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-5 card-shadow border-radius-xl"},[_c('div',{},[_c('v-row',{attrs:{"id":"card"}},[_vm._t("header")],2)],1),_c('v-card-text',{staticClass:"px-0 py-0"},[_vm._t("body",function(){return [_c('div',[_vm._t("befor_table"),_c('Table',{scopedSlots:_vm._u([{key:"top-right-actions",fn:function(){return [_vm._t("top-card")]},proxy:true},{key:"action-table",fn:function(ref){
        var item = ref.item;
return [_vm._t("action-btn-table",null,{"item2":item})]}},{key:"list-item",fn:function(ref){
        var item = ref.item;
return [_vm._t("list-item-table",null,{"item2":item})]}},{key:"column",fn:function(ref){
        var item = ref.item;
return [_vm._t("table-column",null,{"item2":item})]}}],null,true)},[_c('template',{slot:"table"},[_vm._t("table-card")],2),_c('template',{slot:"body"},[_vm._t("table-body")],2)],2)],2),_vm._t("part-body")]})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }