<template>
  <div>
    <v-progress-linear
      v-show="loading"
      slot="progress"
      color="#2d5059"
      indeterminate
    ></v-progress-linear>
  </div>
</template>
<script>
export default {
  props: ["loading"],
};
</script>